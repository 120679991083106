import React, { useMemo } from 'react';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { List } from '@balance-web/list';
import { EntityAvatar } from '@balance-web/avatar';
import { InfoIcon, UsersIcon } from '@balance-web/icon';
import { Button } from '@balance-web/button';
import { plural } from '@balance-web/utils';

import { BookStatusEnum } from '../constants';
import type { BaseBook } from '../types';

import { getBookSubscriptionExpiryDates } from './getBookSubscriptionExpiryDates';

export type BookSelectorItemProps = {
  book: BaseBook;
  isAllowedToSetup: boolean;
  userHasFinancePermission?: boolean;
  onCompleteSetupBookClick: (bookId: string) => void;
  onOpenSelectedBookClick: (bookId: string) => void;
};

export const BookSelectorItem = ({
  book,
  isAllowedToSetup,
  userHasFinancePermission,
  onOpenSelectedBookClick,
  onCompleteSetupBookClick,
}: BookSelectorItemProps) => {
  const bookId = book.bookId;
  const bookName = book.bookName;
  const companyName = book.companyName;
  const isCurrentBook = book.isCurrent;
  const isSetupRequired = book.setupStatus === 'INCOMPLETE';
  const isSharedWithMe = book.isSharedWithMe;
  const subscriptionExpiryDate = book.subscriptionExpiryDate;

  const isBookCancelled =
    BookStatusEnum.valuesEnum.CANCELLED === book.bookStatus;

  const handleSelectBookClick = useMemo(() => {
    if (isCurrentBook) {
      return undefined;
    }
    if (isSetupRequired) {
      return undefined;
    }

    return () => {
      return onOpenSelectedBookClick(bookId);
    };
  }, [bookId, isCurrentBook, isSetupRequired, onOpenSelectedBookClick]);

  const {
    hasExpired,
    isAboutToExpire,
    daysTillExpiry,
  } = getBookSubscriptionExpiryDates({
    expiry: subscriptionExpiryDate,
  });

  return (
    <List.Item
      aria-disabled={isCurrentBook}
      onClick={handleSelectBookClick}
      startElement={
        <EntityAvatar
          guest={isSharedWithMe && true}
          inactive={isBookCancelled}
          type="Person"
          size="small"
          name={bookName}
        />
      }
      endElement={
        <Flex direction="column" alignItems="center">
          {isSetupRequired && isAllowedToSetup && (
            <Button
              size="small"
              variant="text"
              label="Complete setup"
              onClick={() => {
                onCompleteSetupBookClick(bookId);
              }}
            />
          )}

          {isSetupRequired && !isAllowedToSetup && (
            <Flex alignItems="center" gap="small">
              <InfoIcon size="small" color="cautious" />
              <Text size="small" align="left">
                Admin setup required
              </Text>
            </Flex>
          )}

          {isSharedWithMe && !isAllowedToSetup && (
            <Flex
              alignItems="center"
              gap="small"
              paddingRight={isSetupRequired ? 'large' : 'none'}
            >
              <UsersIcon size="small" color="muted" />
              <Text size="small" align="left">
                Shared with me
              </Text>
            </Flex>
          )}
        </Flex>
      }
    >
      <Flex direction="column" alignItems="flex-start" paddingLeft="xsmall">
        <Flex direction="column" alignItems="flex-start">
          <Text size="xsmall" color={isCurrentBook ? 'dim' : 'muted'}>
            {companyName}
          </Text>
          <Text
            size="small"
            weight="medium"
            color={(isCurrentBook && 'dim') || undefined}
          >
            {bookName}
          </Text>
        </Flex>

        {isBookCancelled && (
          <Text color="muted" size="xsmall">
            Read only
          </Text>
        )}

        {isSetupRequired && (
          <Text color="cautious" size="xsmall">
            Setup incomplete
          </Text>
        )}

        {hasExpired && book.bookStatus === 'CANCELLED' && (
          <Text color="critical" size="xsmall">
            This book has expired
          </Text>
        )}

        {/*Only show this text when a user does not have a Credit card attached.*/}
        {isAboutToExpire && !userHasFinancePermission && !isSharedWithMe && (
          <Text align="left" color="critical" size="xsmall">
            Plan will automatically renew in{' '}
            {plural(daysTillExpiry, 'day', 'days')}
          </Text>
        )}
      </Flex>
    </List.Item>
  );
};
