import React from 'react';
import { List } from '@balance-web/list';

import type { BaseBook } from '../types';
import { BookSelectorItem } from '../BookSelectorItem';
import { useBookSwitcherMeta } from '../BookSwitcherMeta/useBookSwitcherMeta';

export type BookSelectorListProps = {
  books: BaseBook[];
  userHasFinancePermission?: boolean;
  onOpenSelectedBookClick: (bookId: string) => void;
  onCompleteSetupBookClick: (bookId: string) => void;
};

export const BookSelectorList = ({
  books,
  userHasFinancePermission,
  onOpenSelectedBookClick,
  onCompleteSetupBookClick,
}: BookSelectorListProps) => {
  const meta = useBookSwitcherMeta();
  return (
    <List>
      {books.map((book) => {
        return (
          <BookSelectorItem
            key={book.bookId}
            book={book}
            isAllowedToSetup={meta.isAllowedToSetupBook(book.bookId)}
            userHasFinancePermission={userHasFinancePermission}
            onCompleteSetupBookClick={onCompleteSetupBookClick}
            onOpenSelectedBookClick={onOpenSelectedBookClick}
          />
        );
      })}
    </List>
  );
};
