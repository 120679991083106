/* eslint-disable jsx-a11y/accessible-emoji */
/** @jsx jsx */

import type { ComponentType, ReactNode } from 'react';
import { Button } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import type { IconProps } from '@balance-web/icon';
import { AlertOctagonIcon } from '@balance-web/icon/icons/AlertOctagonIcon';
import { AlertCircleIcon } from '@balance-web/icon/icons/AlertCircleIcon';
import { AlertTriangleIcon } from '@balance-web/icon/icons/AlertTriangleIcon';
import { CheckCircleIcon } from '@balance-web/icon/icons/CheckCircleIcon';
import { InfoIcon } from '@balance-web/icon/icons/InfoIcon';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

type Action = { action: () => void; label: string };
export type NoticeProps = {
  actions?: {
    primary?: Action;
    secondary?: Action;
  };
  children: ReactNode;
  size?: keyof typeof sizeToPadding;
  tone?: keyof typeof toneToIcon;
  title?: string;
  /**
   * Replace the default icon.
   *
   * The default icons are appropriate for the "tone" and provide context for
   * users that aren't able to discern intent from colour alone. For continuity
   * within our products we recommend that this is rarely used.
   */
  icon?: ComponentType<IconProps>;
};

export const Notice = ({
  actions,
  children,
  size = 'medium',
  tone = 'informative',
  title,
  icon,
}: NoticeProps) => {
  const { typography } = useTheme();

  const Icon = icon || toneToIcon[tone];
  const padding = sizeToPadding[size];
  const iconWrapperHeightHack =
    size === 'small'
      ? `calc(${typography.fontSize.small} * ${typography.leading.base})`
      : undefined;

  return (
    <Flex
      role="alert"
      aria-live="polite"
      // styles
      alignItems="flex-start"
      background={tone}
      border={tone}
      borderRadius={size}
      gap={size}
      paddingX={padding.x}
      paddingY={padding.y}
    >
      <Flex height={iconWrapperHeightHack} alignItems="center">
        <Icon size={size} color={tone} />
      </Flex>

      {/*
       * NOTE: `alignSelf="center"` is a little trick to center-align single
       * lines of text, while allowing text that wraps to be top-aligned,
       * relative to the icon.
       */}
      <Stack gap="small" flex={1} alignSelf="center">
        {title && <Text weight="semibold">{title}</Text>}

        <Text size="small">{children}</Text>

        {actions && (
          <Flex gap="small">
            {actions.primary && (
              <Button
                label={actions.primary.label}
                onClick={actions.primary.action}
                size="small"
                colorScheme="tertiary"
                variant="outline"
              />
            )}
            {actions.secondary && (
              <Button
                label={actions.secondary.label}
                onClick={actions.secondary.action}
                size="small"
                colorScheme="tertiary"
                variant="text"
              />
            )}
          </Flex>
        )}
      </Stack>
    </Flex>
  );
};

// Maps
// ------------------------------

const toneToIcon = {
  accent: AlertCircleIcon,
  informative: InfoIcon,
  critical: AlertOctagonIcon,
  positive: CheckCircleIcon,
  cautious: AlertTriangleIcon,
};

const sizeToPadding = {
  small: { x: 'medium', y: 'small' },
  medium: { x: 'large', y: 'large' },
} as const;
