import type { Context, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import React, { createContext, useContext } from 'react';
import { useLocalstorageState } from 'rooks';

type Acknowledged<TAckKey extends string> = {
  [key in TAckKey]: 'NEEDS_ACK' | 'ACK' | undefined;
};
type AckBooleanMap<T extends string> = {
  [key in T]: boolean;
};

type SessionAcknowledgmentContextShape<
  TAckKey extends string,
  TAckMeta extends AckMeta<TAckKey>
> = {
  /**
   * Map of acknowledgements
   */
  acks: Acknowledged<TAckKey>;

  /**
   * Map of potential acked meta
   */
  meta: TAckMeta;

  /**
   * A map of pending acknowledgements
   *
   * a key is pending if it is NEEDS_ACK
   */
  acked: AckBooleanMap<TAckKey>;
  /**
   * Check if a key is acknowledged
   */
  pending: AckBooleanMap<TAckKey>;
  /**
   * Acknowledge a key
   *
   * sets the key to 'ACK'
   */
  acknowledge: <T extends TAckKey>(key: T) => (meta?: TAckMeta[T]) => void;
  /**
   * Require a key to be acknowledged
   *
   * sets the key to 'NEEDS_ACK'
   */
  require: <T extends TAckKey>(key: T) => (meta?: TAckMeta[T]) => void;
  /**
   * Disregard a key
   *
   * sets the key to undefined
   */
  disregard: <T extends TAckKey>(key: T) => (meta?: TAckMeta[T]) => void;
  /**
   * Reset all acknowledgements
   */
  reset: () => void;
};

type AckMeta<TAckKey extends string> = {
  [key in TAckKey]?: any;
};

/**
 * Create a session acknowledgment context specific to a set of keys
 *
 * Provide your keys as a tuple to this function so that the context
 * can be passed into createSessionAcknowledgementsProvider and createSessionAcknowledgmentHook
 */
export function createSessionAcknowledgmentContext<
  TAckKey extends string,
  TAckMeta extends AckMeta<TAckKey>
>(acks: TAckKey[], meta?: TAckMeta) {
  return createContext<SessionAcknowledgmentContextShape<TAckKey, TAckMeta>>({
    acks: Object.fromEntries(
      acks.map((key) => {
        return [key, undefined];
      })
    ) as Acknowledged<TAckKey>,
    meta: meta as TAckMeta,
    pending: {} as AckBooleanMap<TAckKey>,
    acked: {} as AckBooleanMap<TAckKey>,
    acknowledge: () => {
      return () => {};
    },
    require: () => {
      return () => {};
    },
    disregard: () => {
      return () => {};
    },
    reset: () => {},
  });
}

/**
 * Create a hook to use the session acknowledgment context
 *
 * @see {@link createSessionAcknowledgmentContext}
 */
export function createSessionAcknowledgmentHook<
  TAckKey extends string,
  TAckMeta extends AckMeta<TAckKey>
>(context: Context<SessionAcknowledgmentContextShape<TAckKey, TAckMeta>>) {
  return () => {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error(
        'useAcknowledgedSession must be used within a AcknowledgedSessionProvider'
      );
    }
    return ctx;
  };
}

type StoredAck<TAckKey extends string, TAckMeta extends AckMeta<TAckKey>> = {
  acks: Acknowledged<TAckKey>;
  meta: TAckMeta;
};

function createDefaultAckStore<
  TAckKey extends string,
  TAckMeta extends AckMeta<TAckKey>
>(): StoredAck<TAckKey, TAckMeta> {
  return {
    acks: {} as Acknowledged<TAckKey>,
    meta: {} as TAckMeta,
  };
}

/**
 * Creates an acknowledgment provider that you can wrap your app in.
 *
 * Requires that you create your own context with `createSessionAcknowledgmentContext`
 *
 * @see {@link createSessionAcknowledgmentContext}
 */
export const createSessionAcknowledgementsProvider = <
  TAckKey extends string,
  TAckMeta extends AckMeta<TAckKey>
>(
  storageKey: string,
  context: Context<SessionAcknowledgmentContextShape<TAckKey, TAckMeta>>
) => {
  return ({ children }: PropsWithChildren<{}>) => {
    const defaults = createDefaultAckStore<TAckKey, TAckMeta>();
    const [store, setStore] = useLocalstorageState<
      StoredAck<TAckKey, TAckMeta>
    >(storageKey, defaults);

    const acked = useMemo(() => {
      const output = {} as AckBooleanMap<TAckKey>;
      for (const key in store.acks) {
        output[key] = store.acks[key] === 'ACK';
      }
      return output;
    }, [store.acks]);

    const pending = useMemo((): AckBooleanMap<TAckKey> => {
      const output = {} as AckBooleanMap<TAckKey>;
      for (const key in store.acks) {
        output[key] = store.acks[key] === 'NEEDS_ACK';
      }
      return output;
    }, [store.acks]);

    const createMetaUpdater = useCallback(
      (key: TAckKey) => {
        return (meta: AckMeta<TAckKey>[typeof key]) => {
          setStore({
            ...store,
            meta: {
              ...store.meta,
              [key]: meta,
            },
          });
        };
      },
      [setStore, store]
    );

    const acknowledge = useCallback(
      (key: TAckKey) => {
        setStore({
          ...store,
          acks: {
            ...store.acks,
            [key]: 'ACK',
          },
        });
        return createMetaUpdater(key);
      },
      [createMetaUpdater, setStore, store]
    );

    const disregard = useCallback(
      (key: TAckKey) => {
        setStore({
          ...store,
          acks: {
            ...store.acks,
            [key]: undefined,
          },
        });
        return createMetaUpdater(key);
      },
      [createMetaUpdater, setStore, store]
    );

    const require = useCallback(
      (key: TAckKey) => {
        setStore({
          ...store,
          acks: {
            ...store.acks,
            [key]: 'NEEDS_ACK',
          },
        });

        return createMetaUpdater(key);
      },
      [createMetaUpdater, setStore, store]
    );

    const reset = useCallback(
      () => {
        setStore(defaults);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        // setStore, // infinte loop
        // defaults, // infinte loop
      ]
    );

    return (
      <context.Provider
        value={{
          acks: store.acks,
          acked,
          meta: store.meta,
          pending,
          acknowledge,
          require,
          disregard,
          reset,
        }}
      >
        {children}
      </context.Provider>
    );
  };
};
