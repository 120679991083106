import isDateAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import subDays from 'date-fns/subDays';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import type { ISODate } from '@reckon-web/use-dates';

import { UseDate } from '../core/Date';
import { EXPIRY_WARNING_WINDOW_DAYS } from '../constants';

type UseBookSubscriptionExpiryDatesProps = {
  expiry: string | ISODate | undefined | Date;
  comparedTo?: Date;
};

export function getBookSubscriptionExpiryDates({
  expiry,
  comparedTo = new Date(),
}: UseBookSubscriptionExpiryDatesProps) {
  const { parsedISODate } = UseDate();

  const expiryDate =
    expiry instanceof Date
      ? expiry
      : (expiry && parsedISODate(expiry)) || undefined;

  const hasExpired =
    !!expiryDate &&
    isDateAfter(comparedTo, expiryDate) &&
    differenceInHours(expiryDate, comparedTo) <= 0;

  const isAboutToExpire =
    !!expiryDate &&
    isWithinInterval(comparedTo, {
      start: subDays(expiryDate, EXPIRY_WARNING_WINDOW_DAYS),
      end: expiryDate,
    });
  const daysTillExpiry =
    (!!expiryDate && differenceInDays(expiryDate, comparedTo)) || 0;
  const daysTillExpirySeconds =
    (!!expiryDate && differenceInSeconds(expiryDate, comparedTo)) || 0;
  const daysTillExpiryMinutes =
    (!!expiryDate && differenceInMinutes(expiryDate, comparedTo)) || 0;
  const daysTillExpiryHours =
    (!!expiryDate && differenceInHours(expiryDate, comparedTo)) || 0;

  return {
    hasExpired,
    isAboutToExpire,
    daysTillExpiry,
    daysTillExpiryMinutes,
    daysTillExpirySeconds,
    daysTillExpiryHours,
  };
}
