import { z } from 'zod';

import { HumanisedBooleanLike } from './HumanisedBooleanLike';

export const GeneratedDeploymentConfigSchema = {
  /**
   * Environment Name is dev by default.
   */
  //'dev' | 'staging' | 'prod');
  STAGE: z.union([z.literal('dev'), z.literal('staging'), z.literal('prod')]),

  /**
   * 8 char git hash
   */
  GIT_HASH: z.string(),

  /**
   * Date of the last commit
   */
  GIT_COMMITDATE: z.string(),

  /**
   * Tag of the last commit
   */
  GIT_TAG: z.string(),

  /**
   * Application
   */
  APPLICATION_VERSION: z.string(),

  /**
   * the application name in the package.json
   */
  APPLICATION_PACKAGE_NAME: z.string(),

  /**
   * Used by our load balancer to route requests to the correct stack
   */
  APPLICATION_RKN_WEB_STACK_VERSION: z.string(),

  /**
   * LocalStorage prefix
   */
  APPLICATION_LOCALSTORAGE_PREFIX: z.string(),

  /**
   * Analaytics
   */
  APPLICATION_REPORT_WEB_VITALS: HumanisedBooleanLike.default(true),

  /**
   * Security
   */
  APPLICATION_AUTH_IDLE_FORCELOGOUT_TIMEOUT: HumanisedBooleanLike.default(true),
  APPLICATION_AUTH_IDLE_FORCELOGOUT_TIMEOUTMS: z.coerce
    .number()
    .default(1800000),
  APPLICATION_AUTH_IDLE_WARNING_TIMEOUTMS: z.coerce.number().default(1740000),
  /**
   * Graphql: identify the client name to server logs
   */
  APPLICATION_APOLLO_CLIENT_NAME: z.string(),

  /**
   * Graphql: identify the operation prefix to server logs
   */
  APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME: z.string(),
  /**
   * Graphql: refresh interval (in ms) for queries
   */
  APPLICATION_APOLLO_CLIENT_QUERY_REFRESH_INTERVAL: z.coerce
    .number()
    .default(1800000),
  /**
   * Features
   */
  APPLICATION_FEATURE_USE_MARKINGS: HumanisedBooleanLike.default(false),
};

const SchemaAsObject = z.object(GeneratedDeploymentConfigSchema);

export type GeneratedDeploymentConfig = z.infer<typeof SchemaAsObject>;
